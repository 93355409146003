import React, { useState } from 'react';
import Logo from "./logo.png";
import {connectAccount} from "./MainMint";

const NavBar = () =>{

    

    return( 
       <div></div>
          
    )
}

export default NavBar;